<script setup lang="ts">
import { RouterView } from "vue-router";
import KaraNavbar from "@/components/KaraNavbar.vue";
import KaraBanner from "@/components/KaraBanner.vue";
import KaraFooter from "@/components/KaraFooter.vue";
import { ModalsContainer } from "vue-final-modal";
import useAuthStore from "@/stores/auth";
import { onMounted } from "vue";
import { useSeoMeta } from "@unhead/vue";
import { useI18n } from "vue-i18n";

const auth = useAuthStore();
const i18n = useI18n();

useSeoMeta({
  titleTemplate: (t) => {
    const siteName = i18n.t("catalogue-german-space-actors");
    if (typeof t === "undefined" || t === "") return siteName;

    return t + " | " + siteName;
  },
  ogSiteName: () => i18n.t("catalogue-german-space-actors"),
});

onMounted(async () => await auth.fetchSelf());
</script>

<template>
  <kara-banner />
  <kara-navbar />
  <RouterView
    style="min-height: calc(100vh - var(--navbar-height)); z-index: 0"
  />
  <kara-footer />
  <modals-container />
</template>
