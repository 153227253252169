import type ApiResponse from "@/api/infrastructure/ApiResponse";
import type { FailureDto } from "@/dto/FailureDto";
import useAuthStore from "@/stores/auth";
import router from "@/router";

export default class ApiError extends Error {
  static unknownErrorKey = "unknown-error";
  static fetchFailedKey = "fetch-failed";

  static tryConvertToApiError(e: unknown): ApiError | null {
    if (typeof e === "object" && Object.hasOwnProperty.call(e, "apiResponse")) {
      return e as ApiError;
    }

    return null;
  }

  static async tryGetErrorKey(e: unknown): Promise<string> {
    let errorKey = this.unknownErrorKey;

    const tryGetErrorKeyFromError = (error: object | null): string => {
      if (error === null || !Object.hasOwnProperty.call(error, "message")) {
        console.error("Given error has no message:");
        console.trace(error);

        return this.unknownErrorKey;
      }

      const errorWithMessage = error as { message: string };
      switch (errorWithMessage.message) {
        case "Load failed": // Safari
        case "Failed to fetch": // Chrome/Edge
        case "NetworkError when attempting to fetch resource.": // Firefox
          return this.fetchFailedKey;
        default:
          console.log(
            "using fallback error key because error message '",
            errorWithMessage.message,
            "' is not implemented"
          );

          console.trace(errorWithMessage);

          return this.unknownErrorKey;
      }
    };

    if (typeof e === "object") {
      if (Object.hasOwnProperty.call(e, "apiResponse")) {
        const apiError = e as ApiError;

        try {
          const dto = await apiError.tryConvertToFailure();
          errorKey = dto.errorKey;
        } catch (err) {
          console.error("Failed to read API error response:", err);
        }

        if (errorKey === this.unknownErrorKey) {
          if (typeof apiError.previous === "object") {
            errorKey = tryGetErrorKeyFromError(apiError.previous);
          } else {
            const statusCode = apiError.apiResponse?.original?.status;
            switch (statusCode) {
              case 401:
                await useAuthStore().signOut();
                await router.push({ name: "login" });
                return "token-expired";
              case 403:
                return "access-denied";
              case 404:
                return "resource-not-found";
              case 413:
                return "file-too-large";
              case 422:
                return "invalid-form-data";
              case 429:
                return "too-many-requests";
              default:
                if (typeof statusCode === "number") {
                  if (statusCode >= 500) return ApiError.fetchFailedKey;

                  console.error("Undocumented status code:", statusCode);
                } else {
                  console.warn("No status code available. Got:", statusCode);
                }
                break;
            }
          }
        }
      } else if (e instanceof Error) {
        errorKey = tryGetErrorKeyFromError(e);
      } else {
        console.error("Unknown error object:", e);
        errorKey = tryGetErrorKeyFromError(e);
      }
    } else {
      console.error("Unimplemented error type:", typeof e, e);
    }

    return errorKey;
  }

  constructor(public apiResponse?: ApiResponse, public previous?: unknown) {
    super(apiResponse?.original.statusText);
  }

  async tryConvertToFailure(): Promise<FailureDto> {
    const response = this.apiResponse?.original;
    if (typeof response === "undefined")
      return {
        success: false,
        errorKey: await ApiError.tryGetErrorKey(this.previous),
      };

    try {
      return (await response.json()) as FailureDto;
    } catch (e) {
      return {
        success: false,
        errorKey: await ApiError.tryGetErrorKey(this.previous),
      };
    }
  }
}
